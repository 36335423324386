/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

import { Autocomplete, Paper, TextField } from "@mui/material";
import * as React from "react";

import {
  getOptionsMap,
  isOptionEqualToValueCustom,
  styles,
} from "@/common/client";
import Heading from "@/components/Heading";

const TotalInvestmentsUnderManagementOptions = getOptionsMap();
const TotalInvestmentsUnderManagementOptionsArray = Array.from(
  TotalInvestmentsUnderManagementOptions.keys(),
);

function TotalInvestmentsUnderManagement(props: {
  formik: any;
}): React.JSX.Element {
  const { formik } = props;
  return (
    <Paper
      elevation={10}
      sx={{
        ...styles.questions.papers,
      }}
    >
      <Heading text="Total Investments Under Management" variant="h5" />
      <Heading text="Including Trust, Joint, and IRAs" variant="subtitle1" />
      <Autocomplete
        selectOnFocus
        clearOnBlur
        disableClearable
        handleHomeEndKeys
        size="small"
        id="TotalInvestmentsUnderManagement"
        options={TotalInvestmentsUnderManagementOptionsArray}
        onChange={(e, v) =>
          formik.setFieldValue("TotalInvestmentsUnderManagement", v)
        }
        value={formik.values.TotalInvestmentsUnderManagement || null}
        getOptionLabel={(option) => option}
        isOptionEqualToValue={isOptionEqualToValueCustom}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option}>
              {option}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={
              formik.touched.TotalInvestmentsUnderManagement &&
              Boolean(formik.errors.TotalInvestmentsUnderManagement)
            }
            helperText={
              formik.touched.TotalInvestmentsUnderManagement &&
              formik.errors.TotalInvestmentsUnderManagement
            }
            placeholder="Principal"
          />
        )}
        sx={{
          ...styles.questions.inputs,
        }}
      />
    </Paper>
  );
}

export default TotalInvestmentsUnderManagement;
