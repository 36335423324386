/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

import { Paper, TextField } from "@mui/material";
import * as React from "react";

import { styles } from "@/common/client";
import Heading from "@/components/Heading";

function LastName(props: { formik: any }): React.JSX.Element {
  const { formik } = props;
  return (
    <Paper
      elevation={10}
      sx={{
        ...styles.questions.papers,
      }}
    >
      <Heading text="Last Name" variant="h5" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          id="LastName"
          name="LastName"
          type="text"
          value={formik.values.LastName || ""}
          onChange={formik.handleChange}
          error={formik.touched.LastName && Boolean(formik.errors.LastName)}
          placeholder="Last Name"
          helperText={formik.touched.LastName && formik.errors.LastName}
          sx={{
            ...styles.questions.inputs,
          }}
        />
      </div>
    </Paper>
  );
}

export default LastName;
