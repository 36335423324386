/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

import { Autocomplete, Paper, TextField } from "@mui/material";
import * as React from "react";

import { isOptionEqualToValueCustom, styles } from "@/common/client";
import Heading from "@/components/Heading";

const CombinedFederalAndStateIncomeTaxOptions = [
  "10%",
  "15%",
  "20%",
  "25%",
  "30%",
  "35%",
  "40%",
  "45%",
  "50%",
];

function CombinedFederalAndStateIncomeTax(props: {
  formik: any;
}): React.JSX.Element {
  const { formik } = props;
  return (
    <Paper
      elevation={10}
      sx={{
        ...styles.questions.papers,
      }}
    >
      <Heading text="Combined Federal and State Income Tax Rate" variant="h5" />
      <Heading text="Federal & State Tax Rates" variant="subtitle1" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Autocomplete
          selectOnFocus
          disableClearable
          clearOnBlur
          handleHomeEndKeys
          size="small"
          id="CombinedFederalAndStateIncomeTax"
          options={CombinedFederalAndStateIncomeTaxOptions}
          onChange={(e, v) =>
            formik.setFieldValue("CombinedFederalAndStateIncomeTax", v)
          }
          value={formik.values.CombinedFederalAndStateIncomeTax || null}
          defaultValue={formik.values.CombinedFederalAndStateIncomeTax}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={isOptionEqualToValueCustom}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option}>
                {option}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={
                formik.touched.CombinedFederalAndStateIncomeTax &&
                Boolean(formik.errors.CombinedFederalAndStateIncomeTax)
              }
              helperText={
                formik.touched.CombinedFederalAndStateIncomeTax &&
                formik.errors.CombinedFederalAndStateIncomeTax
              }
              placeholder="Tax Rate"
            />
          )}
          sx={{
            ...styles.questions.inputs,
          }}
        />
      </div>
    </Paper>
  );
}

export default CombinedFederalAndStateIncomeTax;
