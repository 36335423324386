/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

"use client";

import { IS_DEVELOPMENT } from "@/common/constants";

export function createInitialValues():
  | {
      TotalInvestmentsUnderManagement: string;
      AnnualPercentageOnAssetFee: string;
      CombinedFederalAndStateIncomeTax: string;
      FirstName: string;
      LastName: string;
    }
  | {
      TotalInvestmentsUnderManagement: undefined;
      AnnualPercentageOnAssetFee: undefined;
      CombinedFederalAndStateIncomeTax: undefined;
      FirstName: undefined;
      LastName: undefined;
    } {
  let values;
  if (IS_DEVELOPMENT) {
    values = {
      TotalInvestmentsUnderManagement: "$4,000,000",
      AnnualPercentageOnAssetFee: "2.00%",
      CombinedFederalAndStateIncomeTax: "30%",
      FirstName: "John",
      LastName: "Smith",
    };
  } else {
    values = {
      TotalInvestmentsUnderManagement: undefined,
      AnnualPercentageOnAssetFee: undefined,
      CombinedFederalAndStateIncomeTax: undefined,
      FirstName: undefined,
      LastName: undefined,
    };
  }
  return values;
}
