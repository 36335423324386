/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

import { Autocomplete, Paper, TextField } from "@mui/material";
import * as React from "react";

import { isOptionEqualToValueCustom, styles } from "@/common/client";
import Heading from "@/components/Heading";

const AnnualPercentageOnAssetFeeOptions: string[] = [
  "Not Sure",
  "0.50%", // Don cant currently compete with this fee
  "0.75%",
  "1.00%",
  "1.25%",
  "1.50%",
  "1.75%",
  "2.00%",
  "2.25%",
  "2.50%",
  "2.75%",
  "3.00%",
];

function AnnualPercentageOnAssetFee(props: { formik: any }): React.JSX.Element {
  const { formik } = props;
  return (
    <Paper
      elevation={10}
      sx={{
        ...styles.questions.papers,
      }}
    >
      <Heading text="Annual Percentage on Asset Fee" variant="h5" />
      <Heading text="Percent charged by your advisor " variant="subtitle1" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Autocomplete
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          disableClearable
          size="small"
          id="AnnualPercentageOnAssetFee"
          options={AnnualPercentageOnAssetFeeOptions}
          onChange={(e, v) =>
            formik.setFieldValue("AnnualPercentageOnAssetFee", v)
          }
          value={formik.values.AnnualPercentageOnAssetFee || null}
          defaultValue={formik.values.AnnualPercentageOnAssetFee}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={isOptionEqualToValueCustom}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option}>
                {option}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={
                formik.touched.AnnualPercentageOnAssetFee &&
                Boolean(formik.errors.AnnualPercentageOnAssetFee)
              }
              helperText={
                formik.touched.AnnualPercentageOnAssetFee &&
                formik.errors.AnnualPercentageOnAssetFee
              }
              placeholder="Advisor Fee"
            />
          )}
          sx={{
            ...styles.questions.inputs,
          }}
        />
      </div>
    </Paper>
  );
}

export default AnnualPercentageOnAssetFee;
