/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

export const IS_DEVELOPMENT = process.env.NODE_ENV === "development";
export const IS_TEST_MODE_ON =
  process.env.TEST_MODE === "ON" || process.env.TEST_MODE === "1";
export const IS_EMAIL_ON =
  process.env.EMAIL_ON === "ON" || process.env.EMAIL_ON === "1";
export const APP_URL = process.env.APP_URL || "http://localhost:3000";
