/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

import CalculateIcon from "@mui/icons-material/Calculate";
import { Box, Fab } from "@mui/material";
import * as React from "react";

import AnnualPercentageOnAssetFee from "./Questions/AnnualPercentageOnAssetFee";
import CombinedFederalAndStateIncomeTax from "./Questions/CombinedFederalAndStateIncomeTax";
import FirstName from "./Questions/FirstName";
import LastName from "./Questions/LastName";
import TotalInvestmentsUnderManagement from "./Questions/TotalInvestmentsUnderManagement";

const questions = [
  TotalInvestmentsUnderManagement,
  AnnualPercentageOnAssetFee,
  CombinedFederalAndStateIncomeTax,
  FirstName,
  LastName,
];

export default function QuestionsForm(props: {
  formik: any;
}): React.JSX.Element {
  const { formik } = props;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          padding: "10px",
          borderRadius: "10px",
          backgroundImage: "conic-gradient(from 45deg, #204c5d, #51a4c4)",
          // backgroundColor: "#337a96",
          // backgroundImage: "linear-gradient(to bottom, #204c5d, #337a96)",
          maxWidth: "600px",
          margin: "auto",
        }}
      >
        {questions.map((Question, index) => (
          <Question key={index} formik={formik} />
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          margin: "auto",
          contentAlign: "center",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Fab
          variant="extended"
          type="submit"
          size="large"
          sx={{
            width: { lg: "35%", md: "30%" },
            backgroundColor: "secondary.main",
            color: "white",
            "&:hover": {
              backgroundColor: "secondary.light",
              color: "secondary.contrastText",
            },
          }}
        >
          <CalculateIcon sx={{ mr: 1 }} />
          Calculate
        </Fab>
      </Box>
    </form>
  );
}
