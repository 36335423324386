/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

"use client";

import type { SubmissionType } from "@/common/types";

function getOptionsMap(): Map<string, number> {
  // Total Investments Under Management Options
  return new Map([
    ["$1,000,000", 1000000],
    ["$1,500,000", 1500000],
    ["$2,000,000", 2000000],
    ["$2,500,000", 2500000],
    ["$3,000,000", 3000000],
    ["$3,500,000", 3500000],
    ["$4,000,000", 4000000],
    ["$4,500,000", 4500000],
    ["$5,000,000", 5000000],
    ["$5,500,000", 5500000],
    ["$6,000,000", 6000000],
    ["$6,500,000", 6500000],
    ["$7,000,000", 7000000],
    ["$7,500,000", 7500000],
    ["$8,000,000", 8000000],
    ["$8,500,000", 8500000],
    ["$9,000,000", 9000000],
    ["$9,500,000", 9500000],
    ["$10,000,000", 10000000],
  ]);
}

// Fee Schedule
// $1MM to $1.5MM   $6,000
// $2MM             $7,000
// $2.5MM to $3.5MM $8,000
// $4MM to $4.5MM   $10,000
// $5MM to $6.5MM   $15,000
// $7MM to $8.5MM   $18,000
// $9MM to $10MM    $24,000

function getFeeMap(): Map<number, number> {
  return new Map([
    [1000000, 6000],
    [1500000, 6000],
    [2000000, 7000],
    [2500000, 8000],
    [3000000, 8000],
    [3500000, 8000],
    [4000000, 10000],
    [4500000, 10000],
    [5000000, 15000],
    [5500000, 15000],
    [6000000, 15000],
    [6500000, 15000],
    [7000000, 18000],
    [7500000, 18000],
    [8000000, 18000],
    [8500000, 18000],
    [9000000, 24000],
    [9500000, 24000],
    [10000000, 24000],
  ]);
}

const styles = {
  questions: {
    inputs: {
      width: "75%",
      variant: "outlined",
      margin: "auto",
      marginTop: ".5rem",
      marginBottom: ".5rem",
    },
    components: {
      textAlign: "center",
      color: "text.primary",
      marginBottom: "0rem",
      marginTop: "0rem",
    },
    mainComponents: {
      fontSize: "17px",
      textAlign: "center",
      color: "text.primary",
      marginBottom: "0rem",
      marginTop: "0rem",
    },
    papers: {
      padding: {
        xs: "10px 10px 10px 10px",
        sm: "15px 15px 15px 15px",
        md: "20px 20px 20px 20px",
      },
      margin: {
        xs: "10px 10px 20px 10px",
        sm: "15px 15px 20px 15px",
        md: "20px 20px 20px 20px",
      },
      borderRadius: "10px",
    },
  },
};

function isOptionEqualToValueCustom(option: any, value: string) {
  // w.a. for MUI warning about not being able to compare empty string to number
  if (value === "") {
    return false; // true highlights the option, false does not
  }
  return option === value;
}
// eslint-disable-next-line no-unused-vars
async function postSubmission(_submission: SubmissionType): Promise<boolean> {
  return true;
  // const starttime = new Date().getTime();
  // const response = await fetch("api/form", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify(submission),
  //   cache: "no-store",
  // });
  // const durationSeconds = Math.round(new Date().getTime() - starttime) / 1000;
  // const responseJson = await response.json();

  // if (response.ok) {
  //   console.log({
  //     msg: "POST submission successful",
  //     fetchDuration: durationSeconds,
  //     response: responseJson,
  //   });
  // } else {
  //   console.error({
  //     msg: "POST submission failed",
  //     fetchDuration: durationSeconds,
  //     responseJson,
  //     response,
  //   });
  // }
  // return response.ok;
}

export {
  postSubmission,
  getOptionsMap,
  getFeeMap,
  styles,
  isOptionEqualToValueCustom,
};
