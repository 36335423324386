/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

"use client";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";

import { styles } from "@/common/client";
import { IS_DEVELOPMENT } from "@/common/constants";
import { createInitialValues } from "@/common/createInitialValues";
import { schemaIndexValidation as validationSchema } from "@/common/types";
import QuestionsForm from "@/components/QuestionsForm";
import { SubmissionContext } from "@/components/SubmissionContext";
import headerImageLarge from "@/images/logo-2x.png";

const subtitle =
  "Today's low fixed advisor fee is replacing the 35 year old percentage on asset fee \
  and transforming financial outcomes across the wealth management marketplace. \
  Our short survey can help you unlock your potential for tens of thousands of \
  dollars in annual savings with higher net returns and higher net income from \
  your investments each year in retirement.";

const initialValues = createInitialValues();

interface SubmissionValues {
  Date?: string | undefined;
  UUID?: string | undefined;
  HREF?: string | undefined;
  AnnualPercentageOnAssetFee: string | undefined;
}

export default function IndexPage({
  params,
  searchParams,
}: {
  params: { slug: string };
  searchParams: { [key: string]: string | string[] | undefined };
}) {
  // @ts-expect-error
  const { setSubmission } = React.useContext(SubmissionContext);
  const router = useRouter();
  React.useEffect(() => {
    router.prefetch("/results");
  });

  // if (!IS_DEVELOPMENT) {
  //   // destroy the console
  //   console.log = console.error = console.warn = () => {};
  // }

  // { params: {}, searchParams: { viewport: 'mobile' } }
  const { viewport } = searchParams;
  const isMobile = viewport === "mobile";
  const subtitleSize = "h6";

  async function onSubmit(values: SubmissionValues): Promise<void> {
    values.Date = new Date().toLocaleString("en-US", {
      timeZone: "America/New_York",
    });
    values.UUID = window.crypto.randomUUID();
    values.HREF = window.location.href;
    if (values.AnnualPercentageOnAssetFee === "Not Sure") {
      values.AnnualPercentageOnAssetFee = "1.00%";
    }
    if (IS_DEVELOPMENT) {
      console.log({ msg: "Submitting", values });
    }
    setSubmission(values);
    router.push("/results");
  }

  if (IS_DEVELOPMENT) {
    console.log({
      msg: "Rendering IndexPage",
      isMobile,
      params,
      searchParams,
    });
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <Image
        src={headerImageLarge}
        alt="Kiplinger Logo"
        sizes="100vw"
        priority
        placeholder="blur"
        loading="eager"
        style={{
          width: "99%",
          height: "auto",
          margin: "10px",
          // red background for debugging
          // backgroundColor: "red",
        }}
      />
      <Box>
        <Typography
          variant={subtitleSize}
          sx={{
            ...styles.questions.mainComponents,
          }}
          paragraph
        >
          {subtitle}
        </Typography>
        <br />
        <Divider
          sx={{
            color: "silver",
            width: "100%",
            backgroundColor: "silver",
            padding: "1px",
            margin: "auto",
          }}
        />
        <br />
      </Box>
      <QuestionsForm formik={formik} />
    </>
  );
}
