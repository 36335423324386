/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

import * as yup from "yup";

const schemaSubmission = yup.object().shape({
  TotalInvestmentsUnderManagement: yup.string().required(),
  AnnualPercentageOnAssetFee: yup.string().required(),
  CombinedFederalAndStateIncomeTax: yup.string().required(),
  FirstName: yup.string().required(),
  LastName: yup.string().required(),
  Email: yup.string().email().required(),
  CurrentAnnualAdvisorFee: yup.number().required(),
  InvestmentIncome: yup.number().required(),
  AfterTaxInvestmentIncome: yup.number().required(),
  AnnualAdvisorFeeDividedByAfterTaxInvestmentIncome: yup.string().required(),
  AnnualAdvisorFeePercentageOfAfterTaxInvestmentIncome: yup.string().required(),
  FixedAnnualAdvisorSampleFee: yup.number().required(),
  FixedAnnualAdvisorSampleFeeSavings12Months: yup.number().required(),
  FixedAnnualAdvisorSampleFeeSavings24Months: yup.number().required(),
  FixedAnnualAdvisorSampleFeeSavings36Months: yup.number().required(),
  HREF: yup.string().url().required(),
  UUID: yup.string().required(),
  Date: yup.string().required(),
});

const schemaCalculatorResults = yup.object().shape({
  // taxes: yup.number().required(),
  CurrentAnnualAdvisorFee: yup.number().required(),
  InvestmentIncome: yup.number().required(),
  AfterTaxInvestmentIncome: yup.number().required(),
  AnnualAdvisorFeeDividedByAfterTaxInvestmentIncome: yup.string().required(),
  AnnualAdvisorFeePercentageOfAfterTaxInvestmentIncome: yup.string().required(),
  FixedAnnualAdvisorSampleFee: yup.number().required(),
  FixedAnnualAdvisorSampleFeeSavings12Months: yup.number().required(),
  FixedAnnualAdvisorSampleFeeSavings24Months: yup.number().required(),
  FixedAnnualAdvisorSampleFeeSavings36Months: yup.number().required(),
});

const schemaGitInfo = yup.object().shape({
  URL: yup.string().required(),
  Vercel: yup.string().required(),
  "Commit Hash": yup.string().required(),
  Branch: yup.string().required(),
  "Commit Author": yup.string().required(),
  "Commit Date": yup.string().required(),
  "Commit Link": yup.string().required(),
  "Deploy Date (EST)": yup.string().required(),
});

const schemaPDFTemplate = yup.object().shape({
  TotalInvestmentsUnderManagement: yup.string().required(),
  AnnualPercentageOnAssetFee: yup.string().required(),
  CombinedFederalAndStateIncomeTax: yup.string().required(),
  CurrentAnnualAdvisorFee: yup.string().required(),
  InvestmentIncome: yup.string().required(),
  AfterTaxInvestmentIncome: yup.string().required(),
  AnnualAdvisorFeeDividedByAfterTaxInvestmentIncome: yup.string().required(),
  AnnualAdvisorFeePercentageOfAfterTaxInvestmentIncome: yup.string().required(),
  FixedAnnualAdvisorSampleFee: yup.string().required(),
  FixedAnnualAdvisorSampleFeeSavings12Months: yup.string().required(),
  FixedAnnualAdvisorSampleFeeSavings24Months: yup.string().required(),
  FixedAnnualAdvisorSampleFeeSavings36Months: yup.string().required(),
});

const schemaIndexValidation = yup.object({
  TotalInvestmentsUnderManagement: yup
    .string()
    .min(9, "Answer should be of minimum 3 characters length")
    .required("Total Investments Under Management is required"),
  AnnualPercentageOnAssetFee: yup
    .string()
    .min(5, "Answer should be of minimum 3 characters length")
    .required("Annual Percentage On Asset Fee is required"),
  CombinedFederalAndStateIncomeTax: yup
    .string()
    .min(3, "Answer should be of minimum 3 characters length")
    .required("Combined Federal And State Income Tax is required"),
  FirstName: yup
    .string()
    .min(2, "Answer should be of minimum 2 characters length")
    .required("First Name is required"),
  LastName: yup
    .string()
    .min(2, "Answer should be of minimum 2 characters length")
    .required("Last Name is required"),
});

const schemaResultsValidation = yup.object({
  Email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

// type SubmissionType = yup.InferType<typeof schemaSubmission>;
// type CalculatorResultsType = yup.InferType<typeof schemaCalculatorResults>;
// type GitInfoType = yup.InferType<typeof schemaGitInfo>;
// type PDFTemplateType = yup.InferType<typeof schemaPDFTemplate>;

// export type {
//   GitInfoType,
//   SubmissionType,
//   CalculatorResultsType,
//   PDFTemplateType,
// };

// Define TypeScript interfaces
interface SubmissionType extends yup.InferType<typeof schemaSubmission> {}
interface CalculatorResultsType
  extends yup.InferType<typeof schemaCalculatorResults> {}
interface GitInfoType extends yup.InferType<typeof schemaGitInfo> {}
interface PDFTemplateType extends yup.InferType<typeof schemaPDFTemplate> {}

export {
  schemaGitInfo,
  schemaCalculatorResults,
  schemaSubmission,
  schemaPDFTemplate,
  schemaIndexValidation,
  schemaResultsValidation,
};
export type {
  SubmissionType,
  CalculatorResultsType,
  GitInfoType,
  PDFTemplateType,
};
